<template>
    <main>
      <b-button variant="transparent" class="b-light" @click="$router.back()"
        ><i class="b7-back"></i> Regresar</b-button
      >
      <section class="d-flex justify-content-center">
        <!-- Purchase: select payment method -->
        <div class="purchase-box">
          <div class="purchase-header">
            <h2 class="h2-bold">Compra</h2>
            <img src="@/assets/images/logo.webp" alt="" srcset="" />
          </div>
          <div class="purchase-content d-flex flex-column">
            <div>
              <h4 class="h4-semibold text-left">Método de pago</h4>
            </div>
            <div
              class="item d-flex justify-content-between align-items-center click"
              :class="form.payment_method == payment.key ? 'bg-image' : ''"
              @click="form.payment_method = payment.key"
              v-for="payment in payment_methods"
              :key="payment.key"
            >
              <div class="d-flex" style="gap: 10px;">
                <img
                :src="getImageUrl(`images/tokens/${payment.image}.png`)"
                alt=""
              />
              <div class="d-flex flex-column mr-auto text-left">
                <span class="h6-medium">{{ payment.name }}</span>
                <span class="l-light">{{ payment.detail }}</span>
              </div>
              </div>
              <i class="b7-arrow-down"></i>
            </div>
            <div class="purchase-buttons d-flex justify-content-end mt-3">
              <b-button variant="outline-primary">Cancelar</b-button>
              <b-button
                v-if="loading == false"
                :disabled="form.payment_method == null"
                variant="primary"
                @click="onSubmit()"
                >Confirmar compra</b-button
              >
              <b-button variant="primary" disabled v-else>
                Creando pago
              </b-button>
            </div>
          </div>
        </div>
      </section>
    </main>
  </template>
  <script>
  import { mapActions, mapState } from "vuex";
  
  export default {
    props: ["id", "type", "extra"],
    data() {
      return {
        step: 1,
        form: {
          model_id: null,
          model: null,
          extra: null,
          payment_method: null,
          custom: null
        },
        loading: false,
        payment_methods: [
          {
            name: "USDT",
            key: "usdtt",
            image: "usdt",
            detail: "USDT TRC20",
          },
          {
            name: "B7T",
            key: "bta",
            image: "bta",
            detail: "Binance Smart Chain",
          },
        ],
      };
    },
    created () {
        this.form.model_id = this.id
        this.form.model = this.type
        if(this.extra) {
            this.form.extra = this.extra
        }
        if(this.$route.query.custom) {
          this.form.custom = this.$route.query.custom
        }
    },
    methods: {
      ...mapActions("cart", ["storeCart"]),
      onSubmit() {
        this.loading = true;
        this.storeCart(this.form).then((response) => {
          this.loading = false;
          openNotification();
          this.$router.push({
            name: "Purchase-Payment",
            params: { id: response.id },
          });
        });
      },
      selectMontht(months, min) {
        if (this.form.price >= min) {
          this.form.months = months;
        }
      },
      getImageUrl (name) {
        return require(`@/assets/${name}`);
      }
    },
  };
  </script>
  <style scoped>
  main {
    display: grid;
    gap: 32px;
  }
  
  .b-light {
    color: #fff;
    margin-right: auto;
  }
  .purchase-box {
    width: 45%;
    min-width: 890px;
    background: #2a2b3d;
    border-radius: 24px;
    overflow: hidden;
    color: #fff;
  }
  @media (max-width: 1100px) {
    .purchase-box {
      min-width: unset;
      width: 100%;
    }
  }
  .purchase-box .purchase-header {
    background: url("../../assets/images/bg-header-modal.webp");
    padding: 3.25rem 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-size: cover;
    background-position: center bottom;
  }
  .purchase-box .purchase-header img {
    height: 4rem;
  }
  .purchase-box .purchase-content {
    padding: 24px;
    gap: 24px;
  }
  .purchase-box .purchase-content .item {
    gap: 16px;
    padding: 16px;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.05);
  }
  .purchase-box .purchase-content .item img {
    width: 40px;
    height: 40px;
  }
  .purchase-box .purchase-content .item .l-light {
    color: #b5c2d7;
  }
  .purchase-box .purchase-content .item i {
    border-radius: 6.25rem;
    background: rgba(255, 255, 255, 0.1);
    padding: 8px;
    font-size: 16px;
    transform: rotate(-90deg);
  }
  .purchase-box .purchase-content .purchase-buttons {
    gap: 16px;
  }
  .purchase-box .purchase-content i.b7-done {
    color: #48a254;
    font-size: 78px;
  }
  
  .time {
    display: grid;
    gap: 3.2rem;
  }
  .time__logo {
    width: 10rem;
    margin: 0 auto;
    position: relative;
  }
  .time__btns {
    display: flex;
    justify-content: flex-end;
  }
  .time__value {
    position: absolute;
    font-size: 15px;
    font-style: italic;
    font-weight: 900;
    line-height: 21px;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }
  .time__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.4rem;
  }
  @media (max-width: 650px) {
    .time__grid {
      grid-template-columns: 1fr;
    }
  }
  .time__card {
    display: flex;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    flex: 1 0 0;
    border-radius: 24px;
    border: 1px solid var(--brand-border, rgba(255, 255, 255, 0.2));
  }
  .time__card-radio {
    display: flex;
    align-items: center;
    gap: 2.4rem;
    width: 100%;
  }
  .time__card-grid {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  </style>
  